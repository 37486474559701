import React from 'react';
import Layout from '../components/Layout/Layout.js'
import porsche from '../assets/pp.mp4'
import bellovid from '../assets/bellovid.mov'
import './Homepage.css'
import bag from '../assets/classicmint.png'
import sling from '../assets/slinggg.png'
import apparels from '../assets/app.png'
import tech from '../assets/pack.png'
import nfp from '../assets/newpro.png'
import {Link} from 'react-router-dom';
import ww from '../assets/womwom.jpg'
import backpack from '../assets/bbb.jpg'
import women from '../assets/ww.jpg';
import men from '../assets/mmm.jpg';
import owl from '../assets/owlcard.jpg';
import last from '../assets/last.png'
import ig from '../assets/ig.png';
import mm from '../assets/menmen.jpg'
import yt from '../assets/y.png';
import tt from '../assets/tt.png';
import hn from '../assets/hn.png'
import { NavLink } from 'react-router-dom';
import biii from '../assets/bb.png'
import ImageSlider from '../components/Slider.js';


const BackgroundVideo = () => {
  return (
    <Layout>
      {/* video 
       */}
      <div className='vid-main'>
        <video src={bellovid} autoPlay loop muted></video>
        <div className='content'>
          <h1>Fashion is Freedom</h1>
        </div>
      </div>
{/* 
       shop by categories */}
      <div className='shop-by-cat'>
        <div className='sbc-content'>
        </div>
        <div className='category-cards'>
          <div className="catcard">
        
          <div className="detailscategory1">
            <div className='dtcontent1'>
                  <h4 className='white'>Backpacks</h4>
                  <Link to='/backpacks'><button className='bor-btn2'>Shop Now</button></Link>
            </div>
          </div>
          </div>
          <div className="catcard">
        
          <div className="detailscategory2">
          <div className='dtcontent'>
                  <h4>Apparels</h4>
                  <Link to='/apparals'><button className='bor-btn1'>Shop Now</button></Link>
            </div>
          </div>
          </div>
          <div className="catcard">
        
          <div className="detailscategory3">
          <div className='dtcontent'>
                
                  <h4>TechPack</h4>
                  <Link to='/techpack'><button className='bor-btn1'>Shop Now</button></Link>
            </div>
          </div>
          </div>
        </div>
      </div>
{/* 
       bello new product */}
    


{/* <ImageSlider/> */}

    {/* owl card and shipping */}
   <ImageSlider/>


    <div className='homlast'>
      <div className='hl-content'>
        <h1>Come Visit Us</h1>
        <h4>Showroom</h4>
        <p>Jwalakhel, Lalitpur</p>
        <p><a href='https://www.google.com/maps/place/Bello+Nepal/@27.6720378,85.3130606,17z/data=!3m1!4b1!4m6!3m5!1s0x39eb192e79c9f781:0xa6e7f6d733d62463!8m2!3d27.6720378!4d85.3156355!16s%2Fg%2F11l2m6msw8?entry=ttu'>Get Directions</a></p>
        <h4>Store Opening Hours</h4>
        <p>We are open everyday 10:00 AM — 6:30 PM</p>
        <p>9702651054</p>
        <p>bellofornepal@gmail.com</p>

        <div className='socials'>
          <a href='https://www.instagram.com/bello_nepal/' target='1'><img src={ig}/></a>
          <a href='https://www.youtube.com/@BelloNepal' target='1'><img src={yt}/></a>
          <img src={tt}/>
        </div>
      </div>
      <div className='hl-img'>
        <img src={last}/>
      </div>
    </div>
      
    </Layout>
  );
};

export default BackgroundVideo;

